import React, { useState } from 'react'
import { formWrapper, cautionText, label, formDiv, input, abbr, radioWrap, radio, radioLabel, textarea, submitDiv, submit } from './ContactForm.css'
import { navigate } from 'gatsby';

import Title from '../WordPress/Title';

const ContactForm = React.memo(() => {
  const [value, setValue] = React.useState({})
  const [radioStatus, setRadioStatus] = useState('active');

  const [setSubmitted] = useState(false);

  function handleChange(e: any) {
    value[e.target.id] = e.target.value
    setValue({ ...value })
  }

  function handleRadioChange(e: any) {
    setRadioStatus(e.target.value);
  }

  async function onSubmit(e: any) {
    e.preventDefault()
    const response = await window
      .fetch('/api/contact', {
        method: 'POST',
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(value),
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("送信が成功しました");
          navigate('/thanks');
        }
      });
  }

  return (
    <>

      <div className="inner" style={{ marginTop: '10rem' }}>
        <Title Tag='h2' title='CONTACT' subTitle='お問い合わせ' />
        <form onSubmit={onSubmit} method="POST" action="/api/contact" className={formWrapper}>
          <div className={cautionText}>
            メールでのお問い合わせは、下記フォームに必要事項をご記入のうえ、<br className='pc' />
            メッセージを送信してください。
          </div>
          <div className={formDiv}>
            <label className={label}
              title="required">
              お名前<span className={abbr} title="required">*</span>
            </label>
            <input
              name="name"
              id="name"
              value={value['name'] || ``}
              placeholder="山田 太郎"
              required
              onChange={handleChange}
              className={input}
            />
          </div>
          <div className={formDiv}>
            <label className={label}
              title="required">
              ふりがな<span className={abbr} title="required">*</span>
            </label>
            <input
              name="ruby"
              id="ruby"
              value={value['ruby'] || ``}
              placeholder="やまだ　たろう"
              required
              onChange={handleChange}
              className={input}
            />
          </div>
          <div className={formDiv}>
            <label className={label}
              title="required">
              メールアドレス<span className={abbr} title="required">*</span>
            </label>
            <input
              type="email"
              name="email"
              autoComplete='email'
              id="email"
              placeholder="sample@jceoa.org"
              value={value['email'] || ``}
              className={input}
              required
              onChange={handleChange} />
          </div>
          <div className={formDiv}>
            <label className={label}
              title="required">
              お問い合わせ内容<span className={abbr} title="required">*</span>
            </label>
            <textarea
              name="message"
              id="message"
              placeholder="こちらにご記入ください"
              value={value['message'] || ``}
              className={textarea}
              required
              onChange={handleChange} />
          </div>
          <div className={submitDiv}>
            <input className={submit} type="submit" value="上記の内容で送信する" />
          </div>
        </form>
      </div>
    </>

  );
});

export default ContactForm;

import React from 'react';
import ContactForm from '../components/organisms/WordPress/ContactForm';
import SEO from '../utils/seo';

export default function contact() {

  return (
    <>
      <SEO
        title={"お問い合わせ"}
      />
      < ContactForm />
    </>
  )
}
